<template>
  <div
    :class="{
      'forgot-password--jepa': isJEPA,
      'forgot-password--jep': isJEP,
      'app-jepa__background': isJEPA,
    }"
    :style="{ backgroundImage: backgroundUrl }"
    class="forgot-password md-scrollbar md-layout md-alignment-top-center"
  >
    <img
      v-if="isGC"
      alt=""
      class="forgot-password__corner-img"
      src="../assets/images/logo_corner.png"
    />

    <img
      v-if="isLCM"
      alt=""
      class="forgot-password__corner-img logo-corner-lcm"
      src="../assets/images/logo-corner-lcm.svg"
    />

    <md-card
      class="
        forgot-password__form
        md-layout-item
        md-xsmall-size-90
        md-small-size-80
        md-large-size-60
        md-xlarge-size-60
      "
    >
      <md-card-media class="forgot-password__form-media">
        <md-icon v-if="logo" class="forgot-password__logo" v-html="logo" />
        <MeepIconLogoMeep v-else class="forgot-password__logo" />
      </md-card-media>

      <md-card-header>
        <div class="md-title title">{{ $t("forgot-password.title") }}</div>
        <div class="md-subhead">{{ $t("forgot-password.sub-title") }}</div>
      </md-card-header>

      <md-card-content>
        <!-- Le champ email -->
        <div class="meep-input">
          <md-field :class="{ 'md-invalid': errors.has('email') }">
            <label>E-mail</label>

            <md-input
              v-model="form.email"
              v-validate="'required|email'"
              name="email"
              type="email"
              @keyup.enter.native="forgotPassword"
            >
            </md-input>

            <span v-show="errors.has('email')" class="md-error">
              {{ errors.first("email") }}
            </span>
          </md-field>
        </div>
      </md-card-content>

      <md-card-actions>
        <!-- Retour à la connexion -->
        <md-button class="md-primary--inverted" to="/signin">
          {{ $t("forgot-password.cancel-button") }}
        </md-button>

        <md-button
          class="md-raised md-primary reset-password-button"
          @click.native="forgotPassword"
        >
          {{ $t("forgot-password.button") }}
        </md-button>
      </md-card-actions>
    </md-card>
  </div>
</template>

<script>
import Login from "../model/login";
import { mapGetters } from "vuex";
import MeepIconLogoMeep from "../components/icons/MeepIconLogoMeep";

export default {
  name: "ForgotPassword",

  components: {
    MeepIconLogoMeep,
  },

  data() {
    return {
      isDebug: false,
      form: {
        email: null,
      },
    };
  },

  computed: {
    ...mapGetters([
      "organizationTheme",
      "logo",
      "backgroundUrl",
      "isGC",
      "isLCM",
      "isJEPA",
      "isJEP",
      "organizationId",
    ]),
  },

  methods: {
    forgotPassword() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          Login.forgotPassword(this.form.email, this.organizationId)
            .then(() => {
              this.$toasted.global.AppSucces({
                message: this.$t("forget-password.success"),
              });

              this.$router.push("/signin");
            })
            .catch((err) => {
              this.$toasted.global.AppError({
                message: err.msg,
              });
            });
        } else {
          this.$toasted.global.AppError({
            message: this.$t("error.required"),
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "../styles/_variable.scss";

#app {
  .forgot-password {
    height: 100vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: auto;

    .md-input {
      height: 40px;
      @include for-lg {
        height: 50px;
      }
    }

    .md-card-content {
      padding: 0;

      .meep-input {
        width: 95%;
        margin: 0 auto;

        .md-field,

        .md-invalid {
          label {
            top: toRem(18);
            @include for-lg {
              top: toRem(20);
            }
          }
        }

        .md-has-value,
        .md-focused {
          label {
            font-size: toRem(9);
            left: toRem(12);
            top: toRem(10);
            @include for-lg {
              font-size: toRem(12);
              left: toRem(20);
            }
          }
        }
      }
    }

    &.forgot-password--jepa,
    &.forgot-password--jep {
      .forgot-password__form-media {
        height: auto;

        .md-icon {
          width: 235px;
          height: 87px;
          margin-top: 27px;
          margin-bottom: 23px;
        }
      }
    }

    &__corner-img {
      position: absolute;
      transform: rotate(90deg);
      top: 0;
      left: 0;
      width: 20%;

      &.logo-corner-lcm {
        top: 32px;
        width: 20em;
        transform: rotate(0) translate(-1em);
      }
    }

    &__form {
      margin: 100px 0;
      padding: 20px 20px 50px;
      border-radius: 27px;
      box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.2);
      cursor: default;
      @include for-lg {
        padding: toRem(20);
      }

      .md-card {
        cursor: default;

        &-header {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 25px;

          .md-title {
            font-family: var(--font-extrabold);
            font-size: toRem(17);
            @include for-lg {
              font-size: toRem(22);
            }
          }

          .md-subhead {
            opacity: 1;
            font-family: var(--font-x);
            font-size: toRem(14);
            @include for-lg {
              font-size: toRem(17);
            }
          }
        }

        &-actions {
          margin: toRem(30) 0 0 0;
          padding: 0;
          @include for-lg {
            margin: toRem(36) toRem(20) toRem(50) 0;
          }

          .md-ripple {
            padding: 0 toRem(32);
            @include for-lg {
              padding: 0 toRem(43);
            }
          }

          .md-button {
            width: auto;
          }

          .reset-password-button {
            padding: toRem(5) 0;
            height: toRem(35);
            width: auto;
            @include for-lg {
              height: toRem(43);
            }
          }
        }

        &.md-with-hover {
          cursor: default;
        }
      }
    }

    &__form-media {
      width: 100%;
      height: unset;
      display: flex;
      justify-content: center;
      align-items: center;
      @include for-lg {
        height: 150px;
      }

      .md-icon {
        width: 25%;
      }
    }

    .forgot-password__logo {
      max-width: 187px;
      width: 100%;
      height: auto;
      margin-top: toRem(20);
      margin-bottom: 0;
      @include for-lg {
        max-width: 275px;
      }

      /deep/ svg {
        width: 100%;
        height: auto;
      }
    }
  }
}
</style>
